<template>
  <canvas :id="_uid" style="height:30px; width:200px; margin: 5px"></canvas>
</template>

<script>
export default {
  name: "Arrow",
  mounted() {
    let canvas = document.getElementById(this._uid);
    if (canvas.getContext) {
      var cnx = canvas.getContext("2d");
    }
    cnx.fillStyle = "grey";
    cnx.beginPath();
    cnx.moveTo(0, 80);
    cnx.lineTo(290, 80);
    cnx.lineTo(290, 70);
    cnx.lineTo(0, 70);
    cnx.fill();
    cnx.moveTo(300, 75);
    cnx.lineTo(270, 120);
    cnx.lineTo(270, 30);
    cnx.fill();
  },
};
</script>
