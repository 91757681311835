import axios from "axios";
import {format} from "date-fns";

export default {
	methods: {
		async getInsurances(company, flight, pult) {
			try {
				var resp = await this.$axios.get(
					`/Insurance/GetInsurances?company=${company}&flight=${flight}&pult=${pult}`
				)
				console.log(resp.data)
				return resp.data
			} catch (e) {
				console.log('getInsurances - Error')
				throw e
			}
		},
		async getRecieptInfo(regnum, surname, pult) {
			try {
				var resp = await this.$axios.get(
					`/ReceiptData?regnum=${regnum}&surname=${surname}&pult=${pult}`
				)
				console.log(resp.data)
				return resp.data
			} catch (e) {
				console.log('getRecieptInfo - Error')
				throw e
			}
		},
		async refundTicket(pass) {
			this.isRefundingTicket = true

			try {
				var resp = await this.$axios.get(
					`/VoidTickets?regnum=${pass.RegNum}&surname=${pass.Lastname}&pult=${pass.Pult}`
				)
				console.log(resp.data)
				if (resp.data.success == true) pass.IsSold = false
			} catch (e) {
				console.log('refundTicket - Error')
				throw e
			} finally {
				this.isRefundingTicket = false
			}
		},
		async getTickets() {
			try {
				let url = `/FindTkt?air_class=${this.cls}&date=${this.date}&dateBack=${this.back}&fromCity=${this.src}&toCity=${this.dest}&adults=${this.adults}&kids=${this.kids}&babies=${this.bbs}&direct=${this.direct}`
				let resp = await this.$axios.get(url)
				return resp.data
			} catch (e) {
				console.log('getTickets - Error')
				//this.hasErrors = true;
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				throw e
			}
		},
		async getCountries() {
			try {
				let resp = await this.$axios.get(this.$enums.Endpoints.Data.Countries)
				return resp.data
					.map((el) => ({
						text: el.nameRu ? el.nameRu : el.nameEn,
						value: el.codeEn ? el.codeEn : el.codeRu,
					}))
			} catch (e) {
				console.log('getCountries - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return
			}
		},
		async checkOption(tariff = null) {
			let data = {
				Query: {
					PricingVariant: {
						Segments: JSON.parse(this.segments),
						Passengers: this.getPassengersData(
							this.adults,
							this.kids,
							this.bbs
						),
					},
					Pult: this.pult,
					Brand: tariff ? tariff.RequestedBrands : null,
				},
			}
			if (tariff !== null)
				data.Query.PricingVariant.Segments.forEach(
					(e) => (e.Subclass = tariff.Subclass)
				)
			console.log('checkOption')
			console.log(data)
			try {
				let resp = await this.$axios.post(`/CheckFlight`, data)
				console.log(resp.data.Answer.PricingVariant.Variant)
				return resp.data.Answer
			} catch (e) {
				console.log('checkOption - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return
			}
		},
		async checkBrandTariffs(segments, passangers, pult) {
			let data = {
				Query: {
					PricingMonoBrand: {
						Segments: segments,
						Passengers: passangers,
					},
					Pult: pult,
				},
			}
			try {
				let resp = await this.$axios.post(`/checkBrandTariffs`, data)
				return resp.data
			} catch (e) {
				console.log('checkBrandTariffs - Error')
				throw e
			}
		},
		async deleteSvc(regnum, surname, pult) {
			try {
				let resp = await this.$axios.post(`/DelASFromPnr`, {
					regnum: regnum,
					surname: surname,
					pult: pult,
				})
				return true
			} catch (e) {
				console.log('Error - deleteSvc')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return false
			}
		},
		// async proceedToBooking() {
		//   let req = {
		//     Query: {
		//       Pult: this.pult,
		//       Booking: {
		//         Segments: JSON.parse(this.segments),
		//         Passengers: this.passengers,
		//         Customer: {
		//           Phone: {
		//             Type: "mobile",
		//             Comment: "no comment",
		//             Text: this.phone,
		//           },
		//           Email: this.email,
		//         },
		//       },
		//     },
		//   };
		//   console.log("Бронь");
		//   console.log(req);
		//   this.bookLoading = true;
		//   try {
		//     let resp = await this.$axios.post(`/Book`, req);
		//     this.order = resp.data.Answer.Order;
		//     this.regnum = this.order.Pnr.Regnum;
		//     this.surname = this.order.Pnr.Passengers.Passenger[0].Surname;
		//     this.setServices();
		//     this.bookLoading = false;
		//     this.ASDialog = true;
		//   } catch (e) {
		//     console.log("Error - proceedToBooking");
		//     this.hasErrors = true;
		//     this.error = {
		//       Code: e.response.status,
		//       Message: e.response.data.Message,
		//     };
		//     this.bookLoading = false;
		//     return;
		//   }
		// },

	/*	async proceedToBooking(
			segments,
			passengers,
			phone,
			email,
			brand,
			pult
		) {
			let req = {
				Query: {
					Pult: pult,
					Booking: {
						Segments: segments,
						Passengers: passengers,
						Customer: {
							Phone: {
								Type: 'mobile',
								Comment: 'no comment',
								Text: phone,
							},
							Email: email,
						},
						RequestParams: {
							Brands: [{ Text: brand }],
						},
					},
				},
			}
			this.bookLoading = true
			try {
				let resp = await this.$axios.post(`/Book`, req)
				this.order = resp.data.Answer.Order
				this.regnum = this.order.Pnr.Regnum
				this.surname = this.order.Pnr.Passengers.Passenger[0].Surname
				this.setServices()
				//this.ASDialog = true;
			} catch (e) {
				console.log('Error - proceedToBooking')
				throw e
			}
		},*/
		async addASToPnr() {
			let svcs = []
			this.services.forEach((s) => {
				s.Prices.forEach((p) => {
					if (p.Selected)
						svcs.push({
							PassId: p.PassId,
							SegId: p.SegId,
							Rfic: s.Info.Rfic,
							Rfisc: s.Info.Rfisc,
							Emd: s.Info.Emd,
							ServiceType: s.Info.ServiceType,
							Qtty: 1,
							Ssr: s.Info.Ssr,
						})
				})
			})
			let req = {
				Query: {
					Pult: this.pult,
					SvcAdd: {
						Svcs: svcs,
					},
					RegNum: this.regnum,
					Surname: this.surname,
				},
			}
			try {
				let resp = await this.$axios.post(`/AddASToPnr`, req)
				this.book = resp.data.Answer.Order
				return true
			} catch (e) {
				console.log('addASToPnr - Error')
				let message = e.response.data.Message
				let code = e.response.status
				try {
					await this.cancelBooking(
						this.pult,
						this.regnum,
						this.surname
					)
				} catch (error) {
					message = message + ' / ' + e.response.data.Message
					code = code + ' / ' + e.response.status
				}
				throw new Error(message)
				return false
			}
		},
		async proceedToPayment() {
			console.log('proceed to payment')
			this.payLoading = true

			try {
				if (this.selectedServicesCount) await this.addASToPnr()
				else this.book = this.order
				this.payDialog = true
			} catch (e) {
				this.hasErrors = true
				this.error = this.error = {
					Message: e.message,
				}
				this.payDialog = false
			} finally {
				this.payLoading = false
			}
		},
		async payment() {
			this.confirmLoading = true
			let isRefundable = true
			const segments = JSON.parse(this.segments)
			segments.forEach((elem) => {
				isRefundable = isRefundable && elem.IsRefundable
			})

			let req = {
				query: {
					Pult: this.pult,
					paymentExtAuth: {
						regnum: this.regnum,
						surname: this.surname,
						action: 'query',
						paydoc: {
							formpay: 'IN', // CA - запрещено с 2019г
						},
					},
				},
				IsRefundable: isRefundable,
			}
			try {
				let resp = await this.$axios.post(`/Buy`, req)
				this.payDialog = false
				this.ASDialog = false

				await this.$router.push(
					'/show-receipt?regnum=' +
						this.regnum +
						'&surname=' +
						this.surname +
						'&pult=' +
						this.pult +
						'&byBuy=true'
				)
			} catch (e) {
				console.log('Buy - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return
			} finally {
				this.confirmLoading = false
			}
		},
		async cancelBooking(pult, regnum, surname) {
			this.cancelLoading = true
			let req = {
				query: {
					Pult: pult,
					bookingCancel: {
						regnum: {
							Text: regnum,
						},
						surname: surname,
					},
				},
			}

			try {
				let resp = await this.$axios.post(`/BookingCancel`, req)
				this.cancelLoading = false
				this.ASDialog = false
				this.payDialog = false
				this.selectedServicesCount = 0
				return
			} catch (e) {
				console.log('BookingCancel - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				throw e
			}
		},
		async getEmployees(fieldForFind = '', role = 1) {
			try {
				let resp = await this.$axios.get(
					`/user/getusers?fieldForFind=${fieldForFind}&role=${role}`
				)
				return resp.data
			} catch (e) {
				console.log('getEmployees - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return []
			}
		},
		async getDocs() {
			try {
				let resp = await this.$axios.get(`/DocTypes`)
				return resp.data
			} catch (e) {
				console.log('getDocs - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return []
			}
		},

		async getOrder() {
			try {
				let resp = await this.$axios.get(
					`/order?regnum=` +
						this.regnum +
						'&surname=' +
						this.surname +
						'&pult=' +
						this.pult
				)
				this.order = resp.data.Answer.Order
				this.cash = this.totalPaymentAmount
				this.maxPayment = this.totalPaymentAmount
			} catch (e) {
				console.log('getOrder - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return
			}
		},
		async CheckPrintedInfo() {
			try {
				let resp = await this.$axios.get(
					`/CheckPrinted?regnum=` +
						this.regnum +
						'&surname=' +
						this.surname
				)
				this.checkPrinted = resp.data.CheckPrinted
			} catch (e) {
				console.log('getOrder - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return
			}
		},
		async SendTicketToPassenger() {
			let req = {
				query: {
					Pult: this.pult,
					itinReceipt: {
						regnum: this.regnum,
						surname: this.surname,
					},
				},
			}

			try {
				console.log('Start send email')
				let resp = await this.$axios.post(`/Receipt/SendTicket`, req)
				this.pdfByBase64 = resp.data
				console.log('End send email')
			} catch (e) {
				console.log('SendTicketToPassenger - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				return
			}
		},

		async GetPDF(pult, regnum, surname) {
			let req = {
				query: {
					Pult: pult,
					itinReceipt: {
						regnum: regnum,
						surname: surname,
					},
				},
			}
			console.log(req)
			try {
				let resp = await this.$axios.post(`/Receipt/GetPDF`, req)
				console.log(resp)
				return resp.data
			} catch (e) {
				console.log('GetPDF - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data.Message,
					},
				}
				throw e
			}
		},




		// Вызовы ЖД-API
		async getTrains() {
			try {
				/*				const authHeader = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZGFuaWVsIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiIxNiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiZXhwIjoxNzA2MTcyMDIxLCJpc3MiOiJCcG9fVHJhdmVsIiwiYXVkIjoiQnBvX0Jvb2tpbmcifQ.O10NhpLchivQOiWa8lybv27ZlYIOJrPjIid0K-5ZBIE'
                                // Опции для запроса
                                const requestOptions = {
                                    headers: {
                                        'Authorization': authHeader,
                                        'Content-Type': 'application/json-patch+json'
                                    }
                                };*/

				let url = `${this.$store.state.apiAvia + this.$enums.Endpoints.RailwayData.Search}`

				console.log(url)
				var tmp_date = new Date(Date.now())
				let body = {
					From: this.src,
					To: this.dest,
					Date: (this.date.split("-")[0] + "-" + this.date.split("-")[1] + "-" + this.date.split("-")[2]),
					Adult: this.adults,
					Child: this.kids,
					Infant: this.bbs,
				}
				let resp = await this.$axios.post(url, body)
				this.apiVariants = resp.data.data.items
				await this.GetPrice()
				this.searchId = resp.data.data.searchId
				console.log(this.searchId)
				if (this.apiVariants.length) this.initDate()
			} catch (e) {
				console.log('getTrains - Error', e)
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data,
					},
				}
				throw e
			}
		},
		async railwayGetCountries() {
			try {
				let url = `${this.$store.state.railwayApi}/Countries`
				let resp = await this.$axios.get(url)
				return resp.data
					.map((el) => ({ text: el.nameRu, value: el.alpha2Code }))
					.sort((f, s) => (f.nameRu > s.nameRu ? 1 : -1))
			} catch (e) {
				console.log('getCountries - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data,
					},
				}
				return
			}
		},
		async GetPrice() {
			let allCarGroups = [].concat(...this.apiVariants.map(variant => variant.carGroups));

			this.maxPrice = allCarGroups.reduce((max, carGroup) => carGroup.maxTicketPrice > max ? carGroup.maxTicketPrice : max, allCarGroups[0].maxTicketPrice);
			this.minPrice = allCarGroups.reduce((min, carGroup) => carGroup.minTicketPrice < min ? carGroup.minTicketPrice : min, allCarGroups[0].minTicketPrice);
		},

		async bookTrainOrder(segments, passengers, agentAffiliateFee) {
			try {
				const bookingData = {
					segments: segments,
					passengers: passengers,
					partnerAffiliateFee: 0,
					agentAffiliateFee: agentAffiliateFee,
					payType: "card",
					shouldProlongBookingTimeLimit: true
				};
				console.log(bookingData);

				const apiUrl = `${this.$store.state.apiAvia + this.$enums.Endpoints.RailwayOrder.Book}`;
				const response = await this.$axios.post(apiUrl, bookingData);

				if (response.data.success) {
					console.log("Booking successful:", response.data);
					localStorage.removeItem('trainSegment');
					return response.data;
				} else {
					// Handle non-success status codes as needed
					console.error("Booking error:", response.status, response.data);
					return null;
				}
			} catch (error) {
				// Handle network or other errors
				console.error("Booking error:", error.message);
				return null;
			}
		},

		async railwayProceedToBooking(
			phone,
			email,
			origin,
			destination,
			train,
			departure,
			passengers,
			cars
		) {
			let url = `${this.$store.state.railwayApi}/ReservationCreate`
			let req = {
				Phone: phone,
				Email: email,
				OriginCode: origin,
				DestinationCode: destination,
				TrainNumber: train,
				DepartureDate: departure,
				Passengers: passengers.map((item) => {
					let tmp_date = item.Birthdate.split('.')
					let date = new Date(
						tmp_date[2],
						tmp_date[1] - 1,
						tmp_date[0],
						5,
						0,
						0
					)
					return {
						FirstName: item.FirstName,
						MiddleName: item.MiddleName,
						LastName: item.LastName,
						DocumentNumber: item.Doc,
						DocumentType: item.DocType,
						DocumentValidTill: item.Pspexpire,
						CitizenshipCode: item.Nationality,
						Sex: item.Sex,
						Birthday: date,
						Category: item.Category.Text,
						CarNumber: item.Car.vagon,
						CarType: item.Car.carType,
						ServiceClass: item.Car.serviceClassName,
						PLaceNumber: item.Car.place
					}
				}),
			}
			this.bookLoading = true
			try {
				let resp = await this.$axios.post(url, req)
				this.orderId = resp.data.orderId
				this.amount = resp.data.amount
				if (this.amount !== null) {
					this.payDialog = true
				}
				this.bookLoading = false
			} catch (e) {
				console.log('booking - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data,
					},
				}
				this.bookLoading = false
				return
			}
		},

		async railwayReturnTicket() {
			let url = `${this.$store.state.railwayApi}/ReservationReturn`
			this.returnLoading = true
			try {
				let resp = await this.$axios.post(url, {
					orderId: this.orderId,
				})
				console.log('возврат оформлен')
				if (resp.data !== 'Success') {
					this.hasErrors = true
					this.error = {
						Code: '',
						Message: 'Не удалось произвести возврат',
					}
				} else this.returnLoading = false
			} catch (e) {
				console.log('booking - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data,
					},
				}
				this.returnLoading = false
				return
			}
		},


		async railwayCancelBooking() {
			let url = `${this.$store.state.railwayApi}/ReservationCancel?id=${this.orderId}`
			this.cancelBookLoading = true
			try {
				let resp = await this.$axios.get(url)
				console.log(resp)
				if (resp.data !== 'Success') {
					this.hasErrors = true
					this.error = {
						Code: '',
						Message: 'Не удалось отменить бронь',
					}
				}
				this.cancelBookLoading = false
				this.payDialog = false
			} catch (e) {
				console.log('booking - Error')
				this.$store.state.error = {
					show: true,
					value: {
						Code: e.response.status,
						Message: e.response.data,
					},
				}
				this.cancelBookLoading = false
				this.payDialog = false
				return
			}
		},

		async railwayBuy() {
			let url = `${this.$store.state.apiAvia + this.$enums.Endpoints.RailwayOrder.GetOrderInfo}`
			try {
				let body = {
					bilingNumber: this.billingNumber,
					signature: this.signature


				}
				let resp = await this.$axios.get(url)
				if (resp.status !== 200) {
					this.hasErrors = true
					this.error = {
						Code: '',
						Message: 'Не удалось совершить покупку',
					}
					return false
				}
				return true
			} catch (e) {
				console.log('buy - Error')
				this.hasErrors = true
				this.error = {
					Code: e.response.status,
					Message: e.response.data,
				}
				return false
			}
		},

		async railwayBlank() {
			let url = `${this.$store.state.railwayApi}/ReservationBlank?id=${this.orderId}&sendToEmail=true`
			try {
				let resp = await this.$axios.get(url)
				if (resp.status !== 200) {
					this.hasErrors = true
					this.error = {
						Code: '',
						Message:
							'Покупка совершена. Не удалось отправить билеты на почту.',
					}
					return false
				}
				return true
			} catch (e) {
				console.log('blank - Error')
				this.hasErrors = true
				this.error = {
					Code: e.response.status,
					Message: e.response.data,
				}
				return false
			}
		},

		async railwayGetPDF() {
			let url = `${this.$store.state.railwayApi}/ReservationBlank?id=${this.orderId}&sendToEmail=false`
			try {
				let resp = await this.$axios.get(url)
				if (resp.status !== 200) {
					this.hasErrors = true
					this.error = {
						Code: '',
						Message: 'Не удалось получить PDF',
					}
				} else {
					return resp.data
				}
			} catch (e) {
				console.log('pdf - Error')
				this.hasErrors = true
				this.error = {
					Code: e.response.status,
					Message: e.response.data,
				}
				return
			}
		},
	},
}
